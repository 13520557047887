import React, {useState, useEffect} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {FiArrowLeft} from "react-icons/fi";

export default function VolunteersForm(props) {
	const [value, setValue] = useState();

	return (
		<div className="col-12 p-0">
			<div className="card border-0 w-100 b-radius b-shadow p-3 mb-4">
				<div className="row p-3">
					<div className="fs-5 my-3">Կամավորի տվյալներ</div>
					<hr />
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Անուն ազգանուն</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Հեռախոսահամար</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Էլ. հասցե</label>
						<InputText id="username" className="py-3" />
					</div>

					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Ծննդյան օր / տարեթիվ</label>
						<input type="date" id="accept_date" className="form-control py-3" dateFormat="dd/mm/yy" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Ծննդյան վկայական</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Սեռ</label>
						<div className="d-flex align-items-center py-3">
							<div class="form-check me-3">
								<input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
								<label class="form-check-label" for="flexRadioDefault1">
									Արական
								</label>
							</div>
							<div class="form-check ms-3">
								<input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
								<label class="form-check-label" for="flexRadioDefault2">
									Իգական
								</label>
							</div>
						</div>
					</div>
					<div className="flex flex-column col-12 gap-2">
						<label htmlFor="username">Հասցե</label>
						<InputText id="username" className="py-3" />
					</div>
				</div>
			</div>
			<div className="card border-0 w-100 b-radius b-shadow p-3 mt-4">
				<div className="row p-3">
					<div className="fs-5 my-3">Լիազոր անձ</div>
					<hr />
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Ծնողի կամ լիազորված անձի անուն ազգանուն</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Անձնագիր կամ նույնականացման քարտ</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Հեռախոսահամար</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Էլ. հասցե</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-8 gap-2">
						<label htmlFor="username">Հասցե</label>
						<InputText id="username" className="py-3" />
					</div>
				</div>
			</div>
			<div className="card border-0 w-100 b-radius b-shadow p-3 mt-4">
				<div className="d-flex aling-items-center justify-content-center">
					<Link to={"/volunteers"} className="btn btn-outline-secondary d-flex align-items-center me-3">
						<FiArrowLeft className="me-2" />
						Cancel
					</Link>
					<Button label={"Save"} icon={"pi pi-save"} className="btn btn-primary" />
				</div>
			</div>
		</div>
	);
}
