import React, {useState, useEffect, useRef} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Calendar} from "primereact/calendar";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Toast} from "primereact/toast";
import DashboardComponents from "../co_workers/components/DashboardComponents";
import {AutoComplete} from "primereact/autocomplete";
import {Skeleton} from "primereact/skeleton";
import walletInfo from "../../assets/images/icons/unicons/wallet-info.png";
import computer from "../../assets/images/icons/unicons/computer.png";
import chartSuccess from "../../assets/images/icons/unicons/chart-success.png";
import {BiFile} from "react-icons/bi";
import {base, coworker_url, docs_list, docs_application_contracts, docs_application_contracts_single_upload, docs_application_contracts_single_download, docs_application_download_zip} from "../../api";
import axios from "axios";
import {Tooltip} from "primereact/tooltip";
import {useTranslation} from "react-i18next";
import {not_civil_documents, civil_documents, statusTemplate, downloadTemplate} from "./components/Widgets";
import {Tag} from "primereact/tag";
import {downloadFile} from "../../utils/downlodFiles";
import {Link} from "react-router-dom";
import {docTypeNameMap} from "../informers/InformersComponents/static/document_template_names";
import FlagIcon from "../../FlagIconFactory";
const addOrRemove = (arr, item) => (arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item]);

export default function DocumentationIndex() {
	const headers = {"Content-Type": "application/json"};
	const headers_for_Files = {"Content-Type": "multipart/form-data"};
	const credentials = "same-origin";
	const {t} = useTranslation();
	const toast = useRef(null);
	const [dialog, setDialog] = useState(false);
	const [singleFileUploader, setSingleFileUploader] = useState();
	const [filelog, setFilelog] = useState();
	const [fileError, setFileError] = useState();
	const [applicationsList, setApplicationsList] = useState();
	const [documentsLinst, setDocumentsList] = useState();
	const [date, setDate] = useState(new Date());
	const [visible, setVisible] = useState(false);
	const [documentsToCreate, setDocumentsToCreate] = useState([]);
	const [documentsToCreateErrorMessage, setDocumentsToCreateErrorMessage] = useState([]);
	const [englishVersion, setEnglishVersion] = useState(false);

	const [loading, setLoading] = useState(true);
	const [expandedRows, setExpandedRows] = useState(null);
	const [contractType, setContractType] = useState({
		physical: "",
		individual: "",
		legal: "",
	});
	const [partnerInfo, setPartnerInfo] = useState();
	const [selectedPartner, setSelectetPartner] = useState();
	const [filteredPartner, setFilteredPartner] = useState();

	useEffect(() => {
		loadContent();
	}, []);
	const loadContent = () => {
		axios
			.all([axios.get(base + coworker_url), axios.get(base + docs_application_contracts), axios.get(base + docs_list)])
			.then(
				axios.spread((coworkerResponse, applicationContractsResponse, docsListResponse) => {
					setPartnerInfo(coworkerResponse.data);
					const filteredContracts = coworkerResponse.data?.reduce(
						(acc, item) => {
							switch (item.contract_type) {
								case 1:
									acc.physical.push(item);
									break;
								case 2:
									acc.individual.push(item);
									break;
								case 3:
									acc.legal.push(item);
									break;
								default:
									break;
							}
							return acc;
						},
						{physical: [], individual: [], legal: []}
					);

					setContractType(filteredContracts);
					setApplicationsList(applicationContractsResponse.data);
					setDocumentsList(docsListResponse.data);
					setLoading(false);
				})
			)
			.catch(error => {
				console.error("Error loading content:", error);
				setLoading(false);
			});
	};
	function createApplicationHandel(e) {
		e.preventDefault();
		axios({
			method: "POST",
			url: base + docs_application_contracts,
			data: JSON.stringify({document_type_list: documentsToCreate ? documentsToCreate : [], coworker: selectedPartner?.id, contract_date: date?.toISOString().substring(0, 10), is_english: englishVersion}),
			headers: headers,
			credentials: credentials,
		})
			.then(res => {
				loadContent();
				setVisible(false);
				toast.current.show({severity: "success", summary: "Success", detail: "The item is added successfully", life: 3000});
				setDocumentsToCreate();
				setDialog(false);
				setDate();
				setEnglishVersion();
				setDocumentsToCreateErrorMessage();
			})
			.catch(error => {
				setDocumentsToCreateErrorMessage(error.response.data.coworker);
				toast.current.show({severity: "error", summary: "Error", detail: "An error occurred while uploading the file", life: 3000});
				loadContent();
				setDocumentsToCreate();
			});
	}

	function handleUpload(e) {
		e.preventDefault();
		const formData = new FormData();
		formData.append("needs_revising", false);
		formData.append("file", singleFileUploader.file);
		axios({
			method: "PATCH",
			url: base + docs_application_contracts_single_upload + singleFileUploader?.id + "/",
			data: formData,
			headers: headers_for_Files,
			credentials: credentials,
		})
			.then(res => {
				loadContent();
				setVisible(false);
				toast.current.show({severity: "success", summary: "Success", detail: "The item is added successfully", life: 3000});
			})
			.catch(error => {
				setFileError(error.response.data.type);
				toast.current.show({severity: "error", summary: "Error", detail: "An error occurred while uploading the file", life: 3000});
				loadContent();
			});
	}

	function handleNeedsReveasing(e, id) {
		e.preventDefault();
		const formData = new FormData();
		formData.append("needs_revising", true);
		axios({
			method: "PATCH",
			url: base + docs_application_contracts_single_upload + id + "/",
			data: formData,
			headers: headers_for_Files,
			credentials: credentials,
		})
			.then(res => {
				loadContent();
				setVisible(false);
				toast.current.show({severity: "success", summary: "Success", detail: "The item is added successfully", life: 3000});
			})
			.catch(error => {
				setFileError(error.response.data.type);
				toast.current.show({severity: "error", summary: "Error", detail: "An error occurred while uploading the file", life: 3000});
				loadContent();
			});
	}

	const search = event => {
		let query = event.query;
		let _filteredPartner = partnerInfo?.filter(item => item?.full_name_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		setFilteredPartner(_filteredPartner);
	};

	const onRowExpand = event => {};
	const onRowCollapse = event => {};
	const applicationStatustemplate = rowData => {
		return rowData.file ? rowData.needs_revising ? <Tag value="panding" severity="danger" className="opacity-50" /> : <Tag value="Success" severity="success" className="opacity-50" /> : <Tag value="Panging" severity="info" className="opacity-50" />;
	};

	const expandAll = () => {
		let _expandedRows = {};
		docs_application_contracts.forEach(p => (_expandedRows[`${p.id}`] = true));
		setExpandedRows(_expandedRows);
	};

	const collapseAll = () => {
		setExpandedRows(null);
	};

	const fileBody = rowData => {
		return rowData?.file ? (
			<Link to={rowData.file} target="blank" className="p-button p-component p-button-icon-only p-button-outlined p-button-rounded p-button-success" style={{borderRadius: "100%"}}>
				<i className="pi pi-download"></i>
			</Link>
		) : (
			<Button
				icon={"pi pi-upload"}
				rounded
				outlined
				severity="primary"
				onClick={() => {
					setVisible(true);
					setSingleFileUploader(rowData);
				}}
			/>
		);
	};

	const fileTypeTitle = rowData => {
		return (
			<>
				{rowData.is_english ? (
					<span>
						<FlagIcon code={"us"} size={"1x"} className="rounded-1 me-2" /> {t(docTypeNameMap[rowData?.type])}
					</span>
				) : (
					<span>
						<FlagIcon code={"am"} size={"1x"} className="rounded-1 me-2" /> {t(docTypeNameMap[rowData?.type])}
					</span>
				)}
			</>
		);
	};

	const allowExpansion = rowData => {
		return rowData.documents.length > 0;
	};

	const applicationTemplateFiletemplate = rowData => {
		return <Button label="Download file" text rounded severity="info" onClick={e => downloadFile(base + docs_application_contracts_single_download + rowData.id)} />;
	};
	const underReviewBody = rowData => {
		return rowData.file ? (
			rowData.needs_revising ? (
				<>
					<Button
						icon={"pi pi-upload"}
						rounded
						outlined
						severity="primary"
						onClick={() => {
							setVisible(true);
							setSingleFileUploader(rowData);
						}}
					/>
				</>
			) : (
				<Button label="Do you need revising" text rounded onClick={e => handleNeedsReveasing(e, rowData.id)} />
			)
		) : null;
	};
	const rowExpansionTemplate = data => {
		return (
			<div className="px-3 py-0 rounded-4 bg-gray-50">
				<DataTable value={data?.documents} className="bg-gray-50">
					<Column field="type" header={"contract type"} body={fileTypeTitle} sortable className="col-4 bg-gray-50 border-0"></Column>
					<Column field="file" header={"files"} body={underReviewBody} className="col-2 bg-gray-50 border-0 text-start" sortable></Column>
					<Column field="file" header={"files"} body={fileBody} className="col-2 bg-gray-50 border-0" sortable></Column>
					<Column field="file" header={"status"} body={applicationStatustemplate} className="col-2 bg-gray-50 border-0" sortable></Column>
					<Column field="file" header={"Template"} body={applicationTemplateFiletemplate} className="col-4 bg-gray-50 border-0" sortable></Column>
				</DataTable>
			</div>
		);
	};

	return (
		<div className="col-12 p-0">
			<div className="row mb-3 match-height p-0">
				<DashboardComponents image={walletInfo} title={"Ընդհանուր"} info={contractType?.physical.length + contractType?.individual.length + contractType?.legal.length} />
				<DashboardComponents image={computer} title={"Ընթացքում"} info={contractType?.physical.length} />
				<DashboardComponents image={chartSuccess} title={"Ավարտված"} info={contractType?.legal.length} />
			</div>

			<div className="col-12 p-0 my-4">
				<div className="card b-radius b-shadow p-3">
					<p className="d-flex align-items-center text-muted mb-0 text-uppercase"> Ավելացնել նախագծի օգտատեր</p>
					<hr />
					<div className="card flex justify-content-center p-3">
						{partnerInfo ? (
							<AutoComplete
								dropdown
								placeholder="Մուտքագրեք օգտատեր"
								field={"full_name_am"}
								completeMethod={search}
								suggestions={filteredPartner}
								value={selectedPartner}
								onChange={e => setSelectetPartner(e.value)}
								itemTemplate={item => (
									<div
										className="d-flex align-items-top"
										onClick={e => {
											setDialog(true);
										}}
									>
										<div className="d-grid">
											<span className="text-primary">{item?.partner?.full_name_am} </span>
											<span className="text-secondary small">{item?.partner?.address_en ?? "not provided"}</span>
											<span className="text-secondary small">{item?.partner?.passport ?? "not provided"}</span>
										</div>
									</div>
								)}
							/>
						) : (
							<Skeleton width="100%" height="45px"></Skeleton>
						)}
					</div>
				</div>
			</div>

			<div className="card w-100 b-radius b-shadow p-5">
				<div className="card">
					<DataTable value={applicationsList} expandedRows={expandedRows} onRowToggle={e => setExpandedRows(e.data)} onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate} dataKey="id" tableStyle={{minWidth: "60rem"}}>
						<Column expander={allowExpansion} style={{width: "5rem"}} />
						<Column field="coworker_name" header="coworker" className="col-6" sortable />
						<Column field="contract_date" header="contract_date" className="col-3" sortable />
						<Column header="Status" body={statusTemplate} className="col-3" sortable />
						<Column header={null} body={downloadTemplate} className="col-2" />
					</DataTable>
				</div>
			</div>

			<Dialog
				header={selectedPartner?.partner?.full_name_am}
				visible={dialog}
				className="col-xl-6 col-lg-8 col-10 card rounded"
				onHide={() => {
					setDialog(false);
					setDocumentsToCreate();
					setDate();
					setEnglishVersion();
					setDocumentsToCreateErrorMessage();
				}}
			>
				<form onSubmit={e => e.preventDefault()}>
					<div className="row align-items-center p-0">
						<div className="row">
							{selectedPartner?.partner?.legal === "Physical"
								? not_civil_documents.map((item, i) => (
										<div className={`col-lg-6 col-12 stretch-card ${item?.default && "opacity-75 pe-none"}`}>
											<div className={`card w-100 form-check custom-option custom-option-icon  card b-radius b-shadow ${item.default && "checked"}`}>
												<label className="form-check-label custom-option-content d-flex justify-content-between align-items-center" htmlFor={item.type}>
													<span className={`d-flex text-start align-items-center pe-3 ${item?.default && "opacity-75 "}`}>
														<BiFile style={{width: "50px"}} className="fs-5 mt-1" />
														<span className="custom-option-title">{t(item.title)}</span>
													</span>
													<input
														name="checkbox"
														className="form-check-input"
														type="checkbox"
														id={item.type}
														disabled={item?.default === true}
														value={item.type}
														checked={item.default ? true : documentsToCreate?.includes(item.type)}
														onChange={e => {
															setDocumentsToCreate(addOrRemove(documentsToCreate, item.type));
														}}
													/>
												</label>
											</div>
										</div>
								  ))
								: civil_documents.map((item, i) => (
										<div className={`col-lg-6 col-12 stretch-card ${item?.default && "opacity-75 pe-none"}`}>
											<div className={`card w-100 form-check custom-option custom-option-icon  card b-radius b-shadow ${item.default && "checked"}`}>
												<label className="form-check-label custom-option-content d-flex justify-content-between align-items-center" htmlFor={item.type}>
													<span className={`d-flex text-start align-items-center pe-3 ${item?.default && "opacity-75 "}`}>
														<BiFile style={{width: "50px"}} className="fs-5 mt-1" />
														<span className="custom-option-title">{t(item.title)}</span>
													</span>
													<input
														name="checkbox"
														className="form-check-input"
														type="checkbox"
														id={item.type}
														disabled={item?.default === true}
														value={item.type}
														checked={item.default ? true : documentsToCreate?.includes(item.type)}
														onChange={e => {
															setDocumentsToCreate(addOrRemove(documentsToCreate, item.type));
														}}
													/>
												</label>
											</div>
										</div>
								  ))}
							<div className="col-12">
								<div className="row">
									<div className="col-lg-6 col-12">
										<Calendar value={date} className="w-100" dateFormat="yy-mm-dd" onChange={e => setDate(e.value)} />
									</div>
									<div className="flex align-items-center col-lg-6 col-12">
										<input class="form-check-input" type="checkbox" value={englishVersion} onChange={e => setEnglishVersion(true)} id="english" />

										<label htmlFor="english" className="ml-2">
											English Version
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr />
					{documentsToCreateErrorMessage && <div className="text-danger w-100 text-center">{documentsToCreateErrorMessage} </div>}
					<div className="text-center mt-5">
						<Button label="Ստեղծել" rounded severity={"primary"} onClick={e => createApplicationHandel(e)} />
					</div>
				</form>
			</Dialog>

			<Dialog header={t(docTypeNameMap[singleFileUploader?.type])} visible={visible} className="col-xl-3 col-lg-4 col-md-5 col-10 card rounded " onHide={() => (setVisible(false), setFileError(""))}>
				<form enctype="multipart/form-data" onSubmit={e => e.preventDefault()}>
					<div className="col-12 text-center my-5">
						<label htmlFor="upload" className="btn btn-outline-primary " tabIndex="0">
							<span className="d-none d-sm-block">{singleFileUploader?.file ? t("__content_change_document") : t("__content_click_to_add_a_document")}</span>
							<InputText
								type="file"
								onChange={e => {
									setSingleFileUploader({...singleFileUploader, file: e.target.files[0]});
									console.log(e.target.files[0]);
								}}
								hidden
								id="upload"
								accept=""
							/>
						</label>
						{singleFileUploader?.file ? <p className="mt-2">{singleFileUploader?.file?.name}</p> : null}
						{fileError ? <div className="text-danger text-center">{fileError}</div> : null}
						<p className="col-12 text-muted mb-4 mt-2 small">Allowed only .doc, docx filetypes. Max size of 2MB</p>
					</div>
					<hr />
					<div className="text-center mt-5">
						<Button label={t("__content_upload_document")} rounded onClick={handleUpload} />
					</div>
				</form>
			</Dialog>

			<Tooltip target=".tooltiper" />

			<Toast ref={toast} />
		</div>
	);
}
