import {educations} from "../api";

export const sections = {
	oraganization_details: {
		icon: "pi-briefcase",
		color: "info",
		link: "/informers/oraganization_details",
		link_text: "_informers_requisite",
		perm: "organizationdetails",
	},
	acc: {
		icon: "pi-credit-card",
		color: "primary",
		link: "/informers/acc",
		link_text: "_informers_accounts",
		perm: "acc",
	},
	authorizations: {
		icon: "pi-exclamation-triangle",
		color: "danger",
		link: "/informers/authorizations",
		link_text: "_informers_authorizations",
		perm: "permission",
	},
	platform_users: {
		icon: "pi-user",
		color: "primary",
		link: "/informers/platform_users",
		link_text: "_informers_users",
		perm: "user",
	},
	donors: {
		icon: "pi-link",
		color: "info",
		link: "/informers/donors",
		link_text: "_informers_donors",
		perm: "donor",
	},
	organization_types: {
		icon: "pi-home",
		color: "success",
		link: "/informers/organization_types",
		link_text: "_informers_types_of_organizations",
		perm: "organizationtypes",
	},
	service_types: {
		icon: "pi-check",
		color: "info",
		link: "/informers/service_types",
		link_text: "_informers_services",
		perm: "servicetypes",
	},
	statuses: {
		icon: "pi-percentage",
		color: "danger",
		link: "/informers/statuses",
		link_text: "_informers_statuses",
		perm: "statuses",
	},
	spheres: {
		icon: "pi-list",
		color: "danger",
		link: "/informers/spheres",
		link_text: "_informers_spheres",
		perm: "sphere",
	},
	workplace: {
		icon: "pi-clone",
		color: "warning",
		link: "/informers/workplace",
		link_text: "_workplace",
		perm: "positionandservice",
	},
	probation_period: {
		icon: "pi-clone",
		color: "warning",
		link: "/informers/probation_period",
		link_text: "_probation_period",
		perm: "positionandservice",
	},
	position_and_service: {
		icon: "pi-clone",
		color: "warning",
		link: "/informers/position_and_service",
		link_text: "_informers_positions",
		perm: "positionandservice",
	},
	banks: {
		icon: "pi-box",
		color: "primary",
		link: "/informers/banks",
		link_text: "_informers_banks",
		perm: "bank",
	},
	currencies: {
		icon: "pi-pound",
		color: "success",
		link: `/informers/currencies`,
		link_text: "_informers_currency",
		perm: "currency",
	},
	units: {
		icon: "pi-wrench",
		color: "warning",
		link: "/informers/units",
		link_text: "_informers_units_of_measurement",
		perm: "unit",
	},
	languages: {
		icon: "pi-language",
		color: "primary",
		link: "/informers/languages",
		link_text: "_informers_languages",
		perm: "languages",
	},
	taxes: {
		icon: "pi-percentage",
		color: "danger",
		link: "/informers/taxes",
		link_text: "_informers_income_tax_scale",
		perm: "tax",
	},
	expance_category: {
		icon: "pi-chart-pie",
		color: "warning",
		link: "/informers/expance_category",
		link_text: "_informers_category_of_expenses",
		perm: "expancecategory",
	},
	expance_types: {
		icon: "pi-chart-bar",
		color: "danger",
		link: "/informers/expance_types",
		link_text: "_informers_types_of_expenses",
		perm: "expancecategory",
	},
	locations: {
		icon: "pi-map-marker",
		color: "success",
		link: "/informers/locations",
		link_text: "_informers_addresses",
		perm: "location",
	},
	educations: {
		icon: "pi-star",
		color: "primary",
		link: "/informers/educations",
		link_text: "_informers_educations",
		perm: "location",
	},
	tags: {
		icon: "pi-tag",
		color: "info",
		link: "/informers/tags",
		link_text: "_informers_tags",
		perm: "filetag",
	},
	inbox: {
		icon: "pi-comment",
		color: "secondary",
		link: "/informers/inbox",
		link_text: "_informers_correspondence_instructions",
		perm: "_informers_correspondence_instructions",
	},
	document_templates: {
		icon: "pi-file-pdf",
		color: "warning",
		link: "/informers/document_templates",
		link_text: "_informers_document_templates",
		perm: "location",
	},
};
