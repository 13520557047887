import {Button} from "primereact/button";
import {Tooltip} from "primereact/tooltip";
import {FiArchive, FiAnchor, FiAlignLeft, FiCalendar, FiActivity, FiCheckCircle} from "react-icons/fi";

import {BiBarChart, BiBullseye, BiBuoy, BiErrorCircle, BiCalendar, BiRightArrowAlt} from "react-icons/bi";
import {Link} from "react-router-dom";

export const projectTitle = item => {
	return (
		<>
			<div className="d-grid my-1 text-truncate ">
				<code className="d-flex align-items-center text-truncate ">
					<FiAlignLeft className="me-2" />
					{item?.full_name_am && (
						<div className="">
							{item?.full_name_am.substring(0, 50)} {item?.full_name_am?.length > 50 ? "..." : null}
						</div>
					)}
				</code>
				<span className="text-primary small mt-2 d-flex align-items-center">
					<FiAnchor className="me-2" />
					{item?.full_name_en ? item?.full_name_en : <span className="fst-italic text-danger">not provided</span>}
				</span>
			</div>
		</>
	);
};

export const projectStatus = item => {
	return (
		<div className="d-grid my-1 text-truncate ">
			<span className="d-flex align-items-center">{item?.completed ? <FiActivity className="fs-5 text-primary" /> : <FiCheckCircle className="fs-5 text-success" />}</span>
		</div>
	);
};

export const projectID = item => {
	return (
		<div className="d-grid my-1 text-truncate ">
			<code className="d-flex align-items-center">
				<FiArchive className="me-2" />
				{item?.number}
			</code>
			<span className="text-primary small mt-2 d-flex align-items-center">
				<FiAnchor className="me-2" />
				{item?.name}
			</span>
		</div>
	);
};

export const projectDates = item => {
	return (
		item?.end_date && (
			<div className="d-grid my-1 text-truncate ">
				<code className="d-flex small align-items-center">
					<FiCalendar className="me-2" />
					{item?.start_date}
				</code>
				<span className="text-primary small mt-2 d-flex align-items-center">
					<FiCalendar className="me-2" />
					{item?.end_date}
				</span>
			</div>
		)
	);
};

export const projectAction = item => {
	return (
		item?.end_date && (
			<div>
				<Link to={`${"/project/" + item.id}`} className="btn small text-primary d-flex rounded p-0">
					Մանրամասներ <BiRightArrowAlt className="ms-2 fs-5" />
				</Link>
			</div>
		)
	);
};
