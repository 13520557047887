import React, {useState, useRef, useEffect} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {base, usersURL, myProject} from "../../api";
import {ColorizedAvatars} from "../ui/elements/ColorizedAvatars";
import {TabView, TabPanel} from "primereact/tabview";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import ChangeProfileAvatar from "./components/ChangeProfileAvatar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import NgoDetails from "./components/account_details_comps/NgoDetails";
import BankingDetails from "./components/account_details_comps/BankingDetails";
import UserDetails from "./components/account_details_comps/UserDetails";
import UserProductivity from "./components/account_details_comps/UserProductivity";
import UsersRatingBoard from "../user_rating/UsersRatingBoard";

export default function AccountDetail(props) {
	const {id} = useParams();
	const toast = useRef(null);
	const userUrl = base + usersURL + id;

	const [user, setUser] = useState(null);
	const [hovered, setHovered] = useState(false);
	const [profileAvatarDialog, setProfileAvatarDialog] = useState(false);

	useEffect(() => {
		loadContent();
	}, []);

	function loadContent() {
		axios.get(base + userUrl + "/").then(res => {
			setUser(res.data);
		});
		axios.get(base + myProject).then(res => {
			console.log(res.data);
		});
	}

	const handleMouseEnter = () => {
		setHovered(true);
	};

	const handleMouseLeave = () => {
		setHovered(false);
	};

	return (
		<div className="row">
			<div className="col-lg-4 col-12">
				<div className="card w-100 b-radius b-shadow">
					<div className=" row p-3">
						<div className="col-4 d-flex align-items-center flex-column mb-3">
							{user?.profile?.avatar ? (
								!hovered ? (
									<div className="image b-radius b-shadow" style={{backgroundImage: `url(${user?.profile?.avatar})`}} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}></div>
								) : (
									<div className="image b-radius b-shadow d-flex justify-content-center align-items-center" style={{backgroundImage: `url(${user?.profile?.avatar})`}} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
										<Button label="Change profile avatar" text className="text-white fs-6 fw-bold w-100 h-100 blur" onClick={e => setProfileAvatarDialog(true)} />
									</div>
								)
							) : !hovered ? (
								<div className="image b-radius b-shadow text-white d-flex justify-content-center align-items-center display-5 text-uppercase fw-bold" style={user && {backgroundColor: ColorizedAvatars(user?.username?.substring(0, 2))}} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
									{user?.username?.substring(0, 2)}
								</div>
							) : (
								<div className="image b-radius b-shadow text-white d-flex justify-content-center align-items-center display-5 text-uppercase fw-bold" style={user && {backgroundColor: ColorizedAvatars(user?.username?.substring(0, 2))}} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
									<Button label="Change profile avatar" text className="text-white fs-6 fw-bold w-100 h-100 blur" onClick={e => setProfileAvatarDialog(true)} />
								</div>
							)}
						</div>
						<div className="col-8 my-">
							<div className="d-grid mb-3">
								<span className="fs-1">{user?.username}</span>
							</div>
							<hr />
							<div className="d-flex justify-content-start">
								<div className="d-grid">
									<div className="mb-0 lh-1 fs-3 text-primary fw-bold">1.23k</div>
									<span className="small lh-1">Tasks Done</span>
								</div>
								<div className="d-grid ms-4">
									<div className="mb-0 lh-1 fs-3 text-primary fw-bold">568</div>
									<span className="small lh-1">Projects Done</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<UserProductivity />
				<UserDetails data={user?.profile?.partner} />
				<BankingDetails data={user?.profile?.partner} />
				<NgoDetails data={user?.profile?.partner} />
			</div>

			<div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1 pt-2">
				<TabView className="p-0 b-radius b-shadow overflow-hidden">
					<TabPanel header="Projects" headerClassName="p-0" className="p-0">
						<div className="card w-100 mb-4">
							<h5 className="card-header">Stared Projects </h5>
							<div className="table-responsive mb-3">
								<DataTable>
									<Column />
								</DataTable>
							</div>
						</div>
					</TabPanel>
					<TabPanel header="Rating" headerClassName="p-0" className="p-0">
						<div className="table-responsive mb-3">
							<UsersRatingBoard />
						</div>
					</TabPanel>
					<TabPanel header="Activity" headerClassName="p-0" className="p-0">
						<div className="card w-100 mb-4">
							<h5 className="card-header">Activity Timeline</h5>
							<div className="table-responsive mb-3">
								<DataTable>
									<Column />
								</DataTable>
							</div>
						</div>
					</TabPanel>
					<TabPanel header="Settings" headerClassName="p-0" className="p-0">
						<div className="card w-100 mb-5">
							<div className="row"></div>
							<h5 className="card-header">User setting</h5>
							<div className="card-body"></div>
						</div>
					</TabPanel>
				</TabView>

				<Dialog
					header={"Change profile avatar"}
					visible={profileAvatarDialog}
					onHide={() => {
						setProfileAvatarDialog(false);
					}}
					className="col-lg-3 col-10 p-0"
				>
					<ChangeProfileAvatar loadContent={loadContent} toast={toast} userUrl={userUrl} setProfileAvatarDialog={setProfileAvatarDialog} username={user?.username} />
				</Dialog>
				<Toast ref={toast} />
			</div>
		</div>
	);
}
