import React, {useState} from "react";
import axios from "axios";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {BiRightArrowAlt} from "react-icons/bi";
import {TbCurrencyDram} from "react-icons/tb";

export default function EditableRow(props) {
	return (
		<div className="table-responsive p-0">
			{props.isEditing ? (
				<EditableRowInEditMode {...props} />
			) : (
				<EditableRowInReadMode {...props} />
			)}
		</div>
	);
}
function EditableRowInEditMode({
	loadContent,
	setDialogVisibility,
	url,
	toast,
	project,
	submitData,
	typeOptions,
	optionUnit,
}) {
	const [value, setValue] = useState({});
	const headers = {"Content-Type": "application/json"};
	const credentials = "same-origin";
	let isFilled = Object.keys(value).length >= 6;
	for (const key in value) {
		if (!value[key]) {
			isFilled = false;
		}
	}

	function submitData() {
		axios({
			method: "POST",
			url: url,
			data: {
				category: value.category.id,
				count: value.count,
				identifier: value.identifier,
				project: project.id,
				salary: value.salary,
				// type: value.type.id,
				type: "1",
				unit: value.unit.id,
			},
			headers: headers,
			credentials: credentials,
		})
			.then(res => {
				loadContent();
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "The item is added successfully",
					life: 3000,
				});
			})
			.catch(error => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Please add something than press the button ",
					life: 3000,
				});
			});
	}

	return (
		<div className="col-12 p-3">
			<div className="row">
				<div className="col-lg-6">
					<InputText
						onChange={e => setValue({...value, identifier: e.target.value})}
						className="py-3 w-100 m-2"
						placeholder="No:"
					/>
				</div>
				<div className="col-lg-6">
					<Dropdown
						className=" py-1 w-100 m-2"
						options={typeOptions}
						optionLabel={option => option.title_am.substring(0, 35)}
						value={value.type}
						placeholder={value.type ? value.type : "ընտրել հոդվածը"}
						onChange={e => {
							setValue({...value, type: e.value});
						}}
					/>
				</div>
				<div className="col-lg-6">
					<Dropdown
						className=" py-1 w-100 m-2"
						options={typeOptions}
						optionLabel={option => option.title_am.substring(0, 35)}
						value={value.category}
						placeholder={value.category ? value.category : "ընտրել ծախսերի տեսակը"}
						onChange={e => {
							setValue({...value, category: e.value});
						}}
					/>
				</div>
				<div className="col-lg-6">
					<Dropdown
						className=" py-1 w-100 m-2"
						options={optionUnit}
						optionLabel={option => option.title_am.substring(0, 35)}
						value={value.unit}
						placeholder={value.unit ? value.unit : "ընտրել չափման միավորը"}
						onChange={e => {
							setValue({...value, unit: e.value});
						}}
					/>
				</div>
				<div className="col-lg-6">
					<InputText
						onChange={e => setValue({...value, count: e.target.value})}
						className="py-3 w-100 m-2"
						placeholder="Քանակ"
					/>
				</div>
				<div className="col-lg-6">
					<InputText
						onChange={e => setValue({...value, salary: e.target.value})}
						className="py-3 w-100 m-2"
						placeholder="Գին $"
					/>
				</div>
				<div className="col-lg-12 text-center mt-5">
					<Button
						icon={"pi pi-save"}
						rounded
						label="պահպանել"
						severity={isFilled ? "success" : "secondary"}
						onClick={submitData}
						disabled={!isFilled}
					/>
				</div>
			</div>
		</div>
	);
}
function EditableRowInReadMode({
	identifier,
	count,
	salary,
	project,
	category,
	type,
	unit,
	id,
	item,
	budgetByProject,
}) {
	return (
		<div className="col-12 p-0">
			<div className="tabel-div tabel-hover d-flex align-items-center p-0">
				<div className="extra-narrow">{identifier}</div>
				<div className="wide">{type.title_am}</div>
				<div className="extra-narrow ">{category?.code}</div>
				<div className="wide text-start">{category?.title_am}</div>
				<div className="narrow">
					{count} {unit?.title_am}
				</div>
				<div className="d-grid narrow">
					<span>
						{parseFloat(salary)} {project?.currency.title_am}
					</span>
					<span className="small">
						{parseFloat(salary) * parseFloat(project?.currency_value)} <TbCurrencyDram />
					</span>
				</div>
				<div className="d-grid narrow">
					<span>
						{count * parseFloat(salary)} {project?.currency.title_am}
					</span>
					<span className="small">
						{count * parseFloat(salary) * parseFloat(project?.currency_value)} <TbCurrencyDram />
					</span>
				</div>
				<div className="narrow d-flex justify-content-center align-items-center">
					<Link
						to={"/budget/mounthly/" + id}
						className="btn btn-primary d-flex justify-content-center align-items-center text-center rounded-4 p-1"
					>
						<BiRightArrowAlt />
					</Link>
				</div>
			</div>
		</div>
	);
}
