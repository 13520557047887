import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {Button} from "primereact/button";
import {base, expance, unit, budget_by_project, budget_by_project_create} from "../../api";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import EditableRow from "./components/EditableRow";
import {ScrollPanel} from "primereact/scrollpanel";

export default function BudgetProjects(props) {
	const toast = useRef(null);
	const [isEditing, setIsEditing] = useState(true);
	const [dialogVisibility, setDialogVisibility] = useState(false);

	const [optionExpances, setOptionExpances] = useState([]);

	const [optionUnit, setOptionUnit] = useState([]);

	const [budgetByProject, setBudgetByProject] = useState();

	console.log({props}, {optionUnit});
	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios.get(base + budget_by_project + props.projectID + "/").then(res => {
			setBudgetByProject(res.data);
		});
		axios.all([axios.get(base + expance), axios.get(base + unit)]).then(
			axios.spread((...response) => {
				setOptionExpances(response[0].data);
				setOptionUnit(response[1].data);
			})
		);
	};

	const headerElement = (
		<div className="d-grid align-items-center">
			<span className="text-primary">{props?.project?.number}</span>
			<span className="small fw-normal">{props?.project?.name}</span>
			<span className="small fw-normal text-truncate">{props?.project?.full_name_am}</span>
		</div>
	);

	return (
		<>
			<div className="w-100 rounded-4 shadow p-3 card">
				<div className="text-start my-3">
					<Button label="Ավելացնել ծախս" rounded onClick={() => setDialogVisibility(true)} />
				</div>
				{budgetByProject?.length > 0 ? (
					<>
						<div className="col-12 p-0">
							<div className="tabel-div d-flex align-items-center p-0">
								<div className="extra-narrow">No:</div>
								<div className="wide">Հոդված</div>
								<div className="extra-narrow">Կոդ</div>
								<div className="wide">Ծախսերի կատեգորիա</div>
								<div className="narrow">Քանակ</div>
								<div className="narrow">Գին</div>
								<div className="narrow">Ընդհանուր գումար </div>
								<div className="narrow">Մանրամասներ </div>
							</div>
							{budgetByProject?.map((item, i) => (
								<EditableRow
									typeOptions={optionExpances}
									optionUnit={optionUnit}
									{...item}
									project={props?.project}
								/>
							))}
						</div>
						<div className="col-12 py-5">Filter here</div>
					</>
				) : (
					<div className="col-12">
						<div className="card w-100 border-0 text-center">Դեռ նշումներ չկան</div>
					</div>
				)}
				<Dialog
					visible={dialogVisibility}
					modal
					header={headerElement}
					style={{width: "50rem"}}
					onHide={() => {
						if (!dialogVisibility) return;
						setDialogVisibility(false);
					}}
				>
					<EditableRow
						isEditing={isEditing}
						typeOptions={optionExpances}
						optionUnit={optionUnit}
						toast={toast}
						project={props?.project}
						loadContent={loadContent}
						setDialogVisibility={setDialogVisibility}
						url={base + budget_by_project_create}
					/>
				</Dialog>
				<Toast ref={toast} />
			</div>
		</>
	);
}
