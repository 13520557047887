import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {base, projects_url} from "../../api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {TabView, TabPanel} from "primereact/tabview";
import {Button} from "primereact/button";
import walletInfo from "../../assets/images/icons/unicons/wallet-info.png";
import computer from "../../assets/images/icons/unicons/computer.png";
import chartSuccess from "../../assets/images/icons/unicons/chart-success.png";
import briefcase from "../../assets/images/icons/unicons/briefcase.png";
import ProjectDashboardInfoCard from "./components/ProjectDashboardInfoCard";
import NewProject from "./NewProject";
import {projectTitle, projectID, projectDates, projectAction, projectStatus} from "./widgets/Widget";
import {Toast} from "primereact/toast";
import {Link} from "react-router-dom";

export default function ProjectsDashboard() {
	const dt = useRef(null);
	const toast = useRef(null);
	const [ongoingProjects, setOngoingProjects] = useState([]);
	const [complatedProjects, setComplatedProjects] = useState([]);
	const [newProject, setNewProject] = useState(false);
	const [currentState, setCurrentState] = useState();
	useEffect(() => {
		loadData();
	}, []);

	function loadData() {
		axios.get(base + projects_url).then(res => {
			setComplatedProjects(res.data.sort((a, b) => b.id - a.id).filter(item => item.completed == false));
			setOngoingProjects(res.data.sort((a, b) => b.id - a.id).filter(item => item.completed == true));
			console.log(res.data);
		});
	}

	return (
		<>
			<Toast ref={toast} />

			<div className="row mb-3 match-height p-0">
				<ProjectDashboardInfoCard image={walletInfo} title={"Budget"} count={"$23211"} percent={"+23%"} />
				<ProjectDashboardInfoCard image={computer} title={"Total on gloing projects"} count={ongoingProjects?.length} percent={((complatedProjects?.length + ongoingProjects?.length) * 100) / ongoingProjects?.length + "%"} />
				<ProjectDashboardInfoCard image={briefcase} title={"Total completed projects"} count={complatedProjects?.length} percent={((complatedProjects?.length + ongoingProjects?.length) * 100) / ongoingProjects?.length + "%"} />
				<ProjectDashboardInfoCard image={chartSuccess} title={"Total completed projects"} count={ongoingProjects?.length} percent={((complatedProjects?.length + ongoingProjects?.length) * 100) / ongoingProjects?.length + "%"} />
			</div>

			{newProject ? (
				<NewProject setNewProject={setNewProject} loadData={loadData} />
			) : (
				<>
					<div className="col-lg-12 mb-4">
						<div className=" p-3">
							<div className="d-flex justify-content-start">
								<Button className="btn btn-primary px-5" onClick={e => setNewProject(!newProject)}>
									Ավելացնել նոր նախագիծ
								</Button>
							</div>
						</div>
					</div>
					<div className="col-lg-12 mb-4">
						<div className="card w-100 b-radius b-shadow px-3 py-5">
							<TabView>
								<TabPanel header={"Ընթացիկ նախագծեր (" + ongoingProjects?.length + ") հատ"}>
									<DataTable value={ongoingProjects} paginator rows={10} rowsPerPageOptions={[20, 30, 50, 100]} dataKey="id" globalFilterFields={["full_name"]} emptyMessage="Նախագիծ չի գտնվել">
										<Column body={projectStatus} className="col-1"></Column>
										<Column body={projectID} className="col-3"></Column>
										<Column body={projectTitle} className="col-3"></Column>
										<Column body={projectDates} className="col-2"></Column>
										<Column body={projectAction} className="col-2"></Column>
									</DataTable>
								</TabPanel>
								<TabPanel header={"Ավարտված նախագծեր (" + complatedProjects?.length + ") հատ"}>
									<DataTable value={complatedProjects} paginator rows={10} rowsPerPageOptions={[20, 30, 30, 100]} dataKey="id" globalFilterFields={["full_name"]} emptyMessage="Նախագիծ չի գտնվել">
										<Column body={projectStatus} className="col-1"></Column>
										<Column body={projectID} className="col-3"></Column>
										<Column body={projectTitle} className="col-3"></Column>
										<Column body={projectDates} className="col-2"></Column>
										<Column body={projectAction} className="col-2"></Column>
									</DataTable>
								</TabPanel>
							</TabView>
						</div>
					</div>
				</>
			)}
		</>
	);
}
