import React, {useState, useEffect} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {base, budget_by_project_row_get} from "../../api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Budget_Mounthly_to_delete} from "./components/states";
import {FiActivity, FiAlignLeft, FiCalendar} from "react-icons/fi";

export default function BudgetMounthly() {
	const {id} = useParams();
	const [state, setState] = useState();

	useEffect(() => {
		loadData();
	}, [id]);

	function loadData() {
		axios.get(base + budget_by_project_row_get + id + "/").then(res => {
			setState(res.data);
		});
	}

	const yearBody = rowData => {
		const dateString = rowData?.date;
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		return (
			<span className=" text-primary d-flex align-items-center">
				<FiCalendar className="me-2 fs-6" />
				{day}.{month}.{year}
				{/* {rowData?.date.substring(4, 6)} . {rowData?.date.substring(0, 4)} */}
			</span>
		);
	};
	const articleBody = rowData => {
		return (
			<span className=" text-dark d-flex align-items-center">
				<FiAlignLeft className="me-2 fs-6" /> {rowData?.budget.category?.title_am}
			</span>
		);
	};
	const codeBody = rowData => {
		return (
			<span className=" text-dark d-flex align-items-center">
				<FiActivity className="me-2 fs-6" /> {rowData?.budget.category?.code}
			</span>
		);
	};
	const priceBody = rowData => {
		return (
			<span className=" text-dark d-flex align-items-center">
				<FiActivity className="me-2 fs-6" /> {parseFloat(rowData?.budget?.salary)}
			</span>
		);
	};
	console.log(state);
	return (
		<div className="col-12 p-0">
			<div className="card w-10- border-0 b-radius b-shadow p-4">
				<DataTable
					value={state}
					paginator
					rows={20}
					rowsPerPageOptions={[30, 50, 100]}
					editMode="row"
					dataKey="id"
					tableStyle={{minWidth: "50rem"}}
				>
					<Column field="date" header="Ամիս/ամսաթիվ" body={yearBody} className="col-2"></Column>
					<Column field="budget" header="budget Կոդ" body={codeBody} className="col-2"></Column>
					<Column
						field="budget"
						header="Հոդվածi anun"
						body={articleBody}
						className="col-6"
					></Column>
					{/* <Column field="US" header="Արժեք" className="col-1"></Column> */}
					<Column field="Qanak" header="Քանակ " className="col-1"></Column>
					{/* <Column header="Գին" body={priceBody} className="col-1"></Column> */}
					{/* <Column field="Gin" header="Գին" className="col-1"></Column> */}
				</DataTable>
			</div>
		</div>
	);
}
